<template>
  <v-data-table
    :items="agreements"
    :headers="headers"
    item-value="agreementName"
    density="compact"
    mobile-breakpoint="sm"
    :loading="isLoading"
    loading-text="Henter aftaler"
    no-data-text="Ingen aftaler"
    :items-per-page-options="[20, -1]"
    items-per-page="20"
  >
    <template #item.agreementName="{ item }">
      <router-link :to="`/agreement/${item.agreementName}`">
        {{ item.agreementName }}
      </router-link>
    </template>
    <template #item.description="{ item }">
      <div v-if="getDescription(item) === '-'">
        <v-tooltip>
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
            >mdi-minus-circle-outline</v-icon>
          </template>

          Ingen skabeloner. Brug i stedet DocumentDrafter
        </v-tooltip>
      </div>
      <div v-else>
        {{ getDescription(item) }}
      </div>
    </template>
    <template #item.webSiteUrl="{ item }">
      <div v-if="isValidUrl(item.webSiteUrl)">
        <v-icon class="mr-1" size="small">mdi-open-in-new</v-icon>
        <v-btn @click="goToAgreementUrl(item.webSiteUrl)">
          {{ item.webSiteUrl }}
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import agreementsService from '@/agreementsService';
import { AgreementConfigurationSummary } from '@/models/Agreement';

const agreements = ref<AgreementConfigurationSummary[]>([]);
const isLoading = ref(false);

const headers = [
  { title: 'Aftale', value: 'agreementName', sortable: true },
  { title: 'Beskrivelse', value: 'description' },
  { title: 'Website', value: 'webSiteUrl' },
];

async function load() {
  isLoading.value = true;
  agreements.value = await agreementsService.getAgreements();
  isLoading.value = false;
}
void load();

function goToAgreementUrl(url: string) {
  window.open(url);
}

function isValidUrl(url: string) {
  return url.startsWith('http');
}

function getDescription(item: AgreementConfigurationSummary) {
  if (item.agreementName.startsWith('test-0000')) {
    return 'Automatisk test. Må ikke ændres.';
  }
  if (item.agreementName.startsWith('test-')) {
    return 'Aftale til manuel test. Alle må ændre.';
  }
  if (item.deepQuestionnairesList == null) {
    return '-';
  }
  if (item.deepQuestionnairesList.length === 2) {
    return 'Både DIS og RA';
  }

  return 'DIS';
}

</script>
