<template>
  <div>
    <ArrowButton
      v-if="store.isIncognito"
      :to="'/login'"
    >{{ hasToken ? 'Start' : 'Log ind' }}
    </ArrowButton>
    <div v-else>
      <h1>Aftaler</h1>
      <AgreementList />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ArrowButton, hasJwtToken } from '@dims/components';
import AgreementList from './AgreementList.vue';
import { useStore } from '@/store/store';

const store = useStore();
const hasToken = computed(() => hasJwtToken());

</script>
