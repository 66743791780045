<template>
  <v-dialog>
    <v-card :title="getTitle()">
      <v-card-text>
        <div v-for="(change, index) in getLastChangesOnQuestion(question, templateChanges)" :key="index">
          <h3 class="mt-5">{{ labelChange(change) }}{{ additionalChangeText(change) }}</h3><p v-html="getPreviousValue(change)" v-if="change.change !== 'QuestionAdded'" class="previous-value-box"></p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { QuestionDefinition } from '@dims/components';
import { getLastChangesOnQuestion, getLastChangesCountText } from '@/utils';
import { QuestionDefinitionChange } from '../DeepQuestionnaireTemplateWithChanges';

const { question, templateChanges } = defineProps<{
  question: QuestionDefinition;
  templateChanges: QuestionDefinitionChange[];
}>();

function getTitle() {
  return `${getLastChangesCountText(getLastChangesOnQuestion(question, templateChanges).length)} til spørgsmålet '${question.title}'`;
}

function getPreviousValue(change: QuestionDefinitionChange) {
  if (change.previousValue === undefined || change.previousValue === '') {
    return 'Ingen tidligere værdi';
  }

  return change.previousValue;
}

function additionalChangeText(change: QuestionDefinitionChange) {
  if (change.change === 'QuestionAdded' || change.change === 'QuestionRemoved') {
    return '';
  }

  return ', tidligere værdi:';
}

function labelChange(change: QuestionDefinitionChange) {
  switch (change.change) {
    case 'ChangedQuestionType':
      return 'Indholdstype ændret';
    case 'ChangedSupplierAccess':
      return 'Leverandøradgang ændret';
    case 'ChangedCustomerAccess':
      return 'Kundeadgang ændret';
    case 'ChangedName':
      return 'Navn ændret';
    case 'ChangedTitle':
      return 'Titel ændret';
    case 'ChangedInfoText':
      return 'Hjælpetekst ændret';
    case 'ChangedHelpText':
      return 'Infotekst ændret';
    case 'ChangedSupplierText':
      return 'Hjælpetekst (leverandør) ændret';
    case 'DependenciesRemoved':
      return 'Betingelser fjernet';
    case 'ChangedDependencies':
      return 'Betingelser ændret';
    case 'ChangedRequired':
      return 'Obligatorisk ændret';
    case 'ChangedDefaultValue':
      return 'Default ændret';
    case 'OptionsRemoved':
      return 'Options fjernet';
    case 'ChangedOptions':
      return 'Options ændret';
    case 'QuestionAdded':
      return 'Spørgsmål tilføjet';
    case 'QuestionRemoved':
      return 'Spørgsmål fjernet';
    case 'Moved':
      return 'Flyttet';
    default:
      return 'Ukendt ændring';
  }
}

</script>

<style scoped>

.previous-value-box {
  border: 1px solid black;
  padding: 10px;
}

</style>
