<template>
  <v-dialog>
    <v-card :title="getTitle()">
      <v-card-text>
        <div v-for="(removal, index) in removedQuestions" :key="index">
          <h3 class="mt-5">{{ removal.previousValue }}</h3>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import { QuestionDefinition } from '@dims/components';
import { QuestionDefinitionChange } from '../DeepQuestionnaireTemplateWithChanges';
import { getLastChanges } from '@/utils';

const { question, templateChanges } = defineProps<{
  question: QuestionDefinition;
  templateChanges: QuestionDefinitionChange[];
}>();

const removedQuestions = computed(() => getLastChanges(question, templateChanges, 'QuestionRemoved'));

function getTitle() {
  return `${removedQuestions.value.length} underliggende spørgsmål slettet under '${question.title}'`;
}

</script>

<style scoped>

.previous-value-box {
  border: 1px solid black;
  padding: 10px;
}

</style>
